<template>
    <div>
        <div class="logoHeader">
            <div class="subHeader">
                <b-img class="logo" src="/imgLogo90.png"></b-img>
                <span class="subText"> | コミュ力診断結果</span>
            </div>
        </div>
        <ResultUser/>
        <ResultDetail/>
    </div>
</template>

<script>
import ResultUser from './ResultUser.vue'
import ResultDetail from './ResultDetail.vue'

export default {
    name: 'result',
    components: {
        ResultUser,
        ResultDetail
    },
    data(){
        return {
            thumbnailImage: '',
            thumbnailText: ''
        }
    },
    beforeCreate() {
        window.scrollTo(0, 0);
    },
    beforeMount(){
		this.$analytics.logEvent("view_result")
	},
}
</script>

<style scoped>
.logoHeader {
    width: 375px;
    margin: auto;
    text-align: left;
    margin-bottom: 36px;
}

.subHeader {
    margin: 11px 0px 0px 17px;
}

.logo {
    width: 90px;
    height: 22px;
}

.subText {
    font-size: 13px;
    color: rgb(133, 133, 133);
}
</style>
