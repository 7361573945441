<template>
  <div>
      Redirecting...
  </div>
</template>

<script>
export default {
  name: 'redirectintro',
  props: {
  },
  created(){
      this.$router.push({ name: 'intro' })
  }
}
</script>

<style scoped>
</style>
