<template>
	<div>
		<div class="resultHeader">{{resultString.user.title}}</div>
		<b-img class="resultImage" :src="images.userImage"></b-img>
		<div class="rankStatus">
			<div class="rank">
				<b-img class="rankImg" :src="images.rankImage"></b-img>
				<div class="rankName">{{resultString.user.rank}}</div>
				<div class="rankPercent">(コミュ力{{resultString.user.percentage}})</div>
			</div>
			<div class="status">
				<div class="statusDetail" style="margin-top: 8px;">ぴったり判断力
					<div v-if="1 <= resultString.user.status1" class="statusBar statusBarFilled leftBar"></div>
					<div v-else class="statusBar leftBar"></div>

					<div v-if="2 <= resultString.user.status1" class="statusBar statusBarFilled"></div>
					<div v-else class="statusBar"></div>

					<div v-if="3 <= resultString.user.status1" class="statusBar statusBarFilled"></div>
					<div v-else class="statusBar"></div>

					<div v-if="4 <= resultString.user.status1" class="statusBar statusBarFilled"></div>
					<div v-else class="statusBar"></div>

					<div v-if="5 <= resultString.user.status1" class="statusBar statusBarFilled rightBar"></div>
					<div v-else class="statusBar rightBar"></div>
				</div>
				<div class="statusDetail">きっぱり精神力
					<div v-if="1 <= resultString.user.status2" class="statusBar statusBarFilled leftBar"></div>
					<div v-else class="statusBar leftBar"></div>

					<div v-if="2 <= resultString.user.status2" class="statusBar statusBarFilled"></div>
					<div v-else class="statusBar"></div>

					<div v-if="3 <= resultString.user.status2" class="statusBar statusBarFilled"></div>
					<div v-else class="statusBar"></div>

					<div v-if="4 <= resultString.user.status2" class="statusBar statusBarFilled"></div>
					<div v-else class="statusBar"></div>

					<div v-if="5 <= resultString.user.status2" class="statusBar statusBarFilled rightBar"></div>
					<div v-else class="statusBar rightBar"></div>
				</div>
				<div class="statusDetail">しっかり記憶力
					<div v-if="1 <= resultString.user.status3" class="statusBar statusBarFilled leftBar"></div>
					<div v-else class="statusBar leftBar"></div>

					<div v-if="2 <= resultString.user.status3" class="statusBar statusBarFilled"></div>
					<div v-else class="statusBar"></div>

					<div v-if="3 <= resultString.user.status3" class="statusBar statusBarFilled"></div>
					<div v-else class="statusBar"></div>

					<div v-if="4 <= resultString.user.status3" class="statusBar statusBarFilled"></div>
					<div v-else class="statusBar"></div>

					<div v-if="5 <= resultString.user.status3" class="statusBar statusBarFilled rightBar"></div>
					<div v-else class="statusBar rightBar"></div>
				</div>
				<div class="statusDetail" style="margin-bottom: 14px;">やっぱり論理力
					<div v-if="1 <= resultString.user.status4" class="statusBar statusBarFilled leftBar"></div>
					<div v-else class="statusBar leftBar"></div>

					<div v-if="2 <= resultString.user.status4" class="statusBar statusBarFilled"></div>
					<div v-else class="statusBar"></div>

					<div v-if="3 <= resultString.user.status4" class="statusBar statusBarFilled"></div>
					<div v-else class="statusBar"></div>

					<div v-if="4 <= resultString.user.status4" class="statusBar statusBarFilled"></div>
					<div v-else class="statusBar"></div>

					<div v-if="5 <= resultString.user.status4" class="statusBar statusBarFilled rightBar"></div>
					<div v-else class="statusBar rightBar"></div>
				</div>
			</div>
		</div>
		<div class="detail">
			<div class="detailHeader">あなたはこんな人</div>
			<div class="detailText" v-bind:style="readMoreText">
				<div v-for="(detail, index) in resultString.user.details" v-bind:key="index">
					• {{detail}}
				</div>
			</div>
			<button v-if="!readMore" class="moreButton" @click="openReadMore()">もっと見る</button>
			<button v-else-if="readMore" class="moreButton" @click="closeReadMore()">閉じる</button>
		</div>
		<div class="skillList">
			<div class="detailHeader">特徴</div>
			<div class="skills">
				<div style="display: inline-block; left: 0px; margin-right: 32px;" @mouseover="skill1Show = true" @mouseleave="skill1Show = false">
					<b-img class="skillImg" :src="images.skillImage1"></b-img>
					<div class="skillName" id="skill1">{{resultString.user.skill1}}</div>
				</div>
				<div style="display: inline-block; margin-right: 32px;" @mouseover="skill2Show = true" @mouseleave="skill2Show = false">
					<b-img class="skillImg" :src="images.skillImage2"></b-img>
					<div class="skillName" id="skill2">{{resultString.user.skill2}}</div>
				</div>
				<div style="display: inline-block" @mouseover="skill3Show = true" @mouseleave="skill3Show = false">
					<b-img class="skillImg" :src="images.skillImage3"></b-img>
					<div class="skillName" id="skill3">{{resultString.user.skill3}}</div>
				</div>
			</div>
		</div>
		<div class="mateList">
			<div class="mate" style="margin-right: 13px;">
				<div class="mateHeader">相性 Good</div>
				<div class="mateCard">
					<b-img class="mateImg" :src="images.mateImage" @click="moveToRType(resultString.user.mate)"></b-img>
					<div class="mateName">{{resultString.mate.title}}</div>
				</div>
			</div>
			<div class="mate">
				<div class="mateHeader">相性 Bad</div>
				<div class="mateCard">
					<b-img class="mateImg" :src="images.countImage" @click="moveToRType(resultString.user.counter)"></b-img>
					<div class="mateName">{{resultString.counter.title}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import resultJson from '../assets/string/result.json'

export default {
	name: 'resultuser',
	components: {},
	data(){
		return {
			images:{
				rankImage: '',
				userImage: '',
				skillImage1: '',
				skillImage2: '',
				skillImage3: '',
				mateImage: '',
				countImage: '',
				thumbnailImage: ''
			},
			resultString: {
				user: '',
				mate: '',
				counter: '',
				skills: ''
			},
			readMore: false,
			skill1Show: false,
			skill2Show: false,
			skill3Show: false
		}
	},
	computed:{
		readMoreText(){
			if(!this.readMore){
				return 'height: 53px'
			}
			else{
				return ''
			}
		}
	},
	beforeMount(){
		
		this.resultString.user = resultJson['JP'][this.$route.params.type] // get user data by type
		this.resultString.mate = resultJson['JP'][this.resultString.user.mate] // 궁합 데이터 가져오기
		this.resultString.counter = resultJson['JP'][this.resultString.user.counter]
		this.resultString.skills = resultJson['JP']['skills']

		switch(this.$route.params.type){ // 이미지 가져오기 (파일네임 규격화 필요.....)
			case 'enfj':
				this.images.rankImage = '/tier/icBadgeDiamond.png'
				this.images.userImage = '/results/우리엄마.png'
				this.images.skillImage1 = '/skills/따발총.png'
				this.images.skillImage2 = '/skills/눈치주기.png'
				this.images.skillImage3 = '/skills/팩트폭행.png'
				this.images.mateImage = '/results/카리스마.png'
				this.images.countImage = '/results/얘랑 싸우지마.png'
				this.images.thumbnailImage = '/thumbnails/썸네일_우리 엄마.png'
				break
			case 'istj':
				this.images.rankImage = '/tier/icBadgeDiamond.png'
				this.images.userImage = '/results/얘랑 싸우지마.png'
				this.images.skillImage1 = '/skills/팩트폭행.png'
				this.images.skillImage2 = '/skills/빅데이터.png'
				this.images.skillImage3 = '/skills/따발총.png'
				this.images.mateImage = '/results/꼼짝마.png'
				this.images.countImage = '/results/우리엄마.png'
				this.images.thumbnailImage = '/thumbnails/썸네일_얘랑 싸우지마.png'
				break
			case 'estp':
				this.images.rankImage = '/tier/icBadgePlatinum.png'
				this.images.userImage = '/results/악마.png'
				this.images.skillImage1 = '/skills/아이건좀_.png'
				this.images.skillImage2 = '/skills/사자후.png'
				this.images.skillImage3 = '/skills/로봇감수성.png'
				this.images.mateImage = '/results/꼬꼬마.png'
				this.images.countImage = '/results/조마조마.png'
				this.images.thumbnailImage = '/thumbnails/썸네일_악마.png'
				break
			case 'entj':
				this.images.rankImage = '/tier/icBadgePlatinum.png'
				this.images.userImage = '/results/카리스마.png'
				this.images.skillImage1 = '/skills/으리!.png'
				this.images.skillImage2 = '/skills/훈화말씀.png'
				this.images.skillImage3 = '/skills/예상적중.png'
				this.images.mateImage = '/results/우리엄마.png'
				this.images.countImage = '/results/파노라마.png'
				this.images.thumbnailImage = '/thumbnails/썸네일_카리스마.png'
				break
			case 'infj':
				this.images.rankImage = '/tier/icBadgePlatinum.png'
				this.images.userImage = '/results/트로이의목마.png'
				this.images.skillImage1 = '/skills/예상적중.png'
				this.images.skillImage2 = '/skills/답정너.png'
				this.images.skillImage3 = '/skills/아이건좀_.png'
				this.images.mateImage = '/results/파노라마.png'
				this.images.countImage = '/results/라떼이즈홀스.png'
				this.images.thumbnailImage = '/thumbnails/썸네일_트로이의 목마.png'
				break
			case 'intj':
				this.images.rankImage = '/tier/icBadgeGold.png'
				this.images.userImage = '/results/꼼짝마.png'
				this.images.skillImage1 = '/skills/답정너.png'
				this.images.skillImage2 = '/skills/도르마무.png'
				this.images.skillImage3 = '/skills/훈화말씀.png'
				this.images.mateImage = '/results/얘랑 싸우지마.png'
				this.images.countImage = '/results/천군만마.png'
				this.images.thumbnailImage = '/thumbnails/썸네일_꼼짝마.png'
				break
			case 'entp':
				this.images.rankImage = '/tier/icBadgeGold.png'
				this.images.userImage = '/results/꼬꼬마.png'
				this.images.skillImage1 = '/skills/무지개반사.png'
				this.images.skillImage2 = '/skills/아이건좀_.png'
				this.images.skillImage3 = '/skills/팩트폭행.png'
				this.images.mateImage = '/results/악마.png'
				this.images.countImage = '/results/고구마.png'
				this.images.thumbnailImage = '/thumbnails/썸네일_꼬꼬마.png'
				break
			case 'isfj':
				this.images.rankImage = '/tier/icBadgeGold.png'
				this.images.userImage = '/results/파노라마.png'
				this.images.skillImage1 = '/skills/빅데이터.png'
				this.images.skillImage2 = '/skills/따발총.png'
				this.images.skillImage3 = '/skills/예상적중.png'
				this.images.mateImage = '/results/트로이의목마.png'
				this.images.countImage = '/results/카리스마.png'
				this.images.thumbnailImage = '/thumbnails/썸네일_파노라마.png'
				break
			case 'esfj':
				this.images.rankImage = '/tier/icBadgeGold.png'
				this.images.userImage = '/results/천군만마.png'
				this.images.skillImage1 = '/skills/눈치주기.png'
				this.images.skillImage2 = '/skills/으리!.png'
				this.images.skillImage3 = '/skills/딴청피우기.png'
				this.images.mateImage = '/results/라떼이즈홀스.png'
				this.images.countImage = '/results/꼼짝마.png'
				this.images.thumbnailImage = '/thumbnails/썸네일_천군만마.png'
				break
			case 'estj':
				this.images.rankImage = '/tier/icBadgeSilver.png'
				this.images.userImage = '/results/라떼이즈홀스.png'
				this.images.skillImage1 = '/skills/훈화말씀.png'
				this.images.skillImage2 = '/skills/팩트폭행.png'
				this.images.skillImage3 = '/skills/빅데이터.png'
				this.images.mateImage = '/results/천군만마.png'
				this.images.countImage = '/results/트로이의목마.png'
				this.images.thumbnailImage = '/thumbnails/썸네일_latte is horse.png'
				break
			case 'esfp':
				this.images.rankImage = '/tier/icBadgeSilver.png'
				this.images.userImage = '/results/마그마(임마).png'
				this.images.skillImage1 = '/skills/사자후.png'
				this.images.skillImage2 = '/skills/아이건좀_.png'
				this.images.skillImage3 = '/skills/으리!.png'
				this.images.mateImage = '/results/회전목마.png'
				this.images.countImage = '/results/f=ma.png'
				this.images.thumbnailImage = '/thumbnails/썸네일_마그마.png'
				break
			case 'enfp':
				this.images.rankImage = '/tier/icBadgeSilver.png'
				this.images.userImage = '/results/회전목마.png'
				this.images.skillImage1 = '/skills/도르마무.png'
				this.images.skillImage2 = '/skills/무지개반사.png'
				this.images.skillImage3 = '/skills/따발총.png'
				this.images.mateImage = '/results/마그마(임마).png'
				this.images.countImage = '/results/유니콘.png'
				this.images.thumbnailImage = '/thumbnails/썸네일_회전목마.png'
				break
			case 'intp':
				this.images.rankImage = '/tier/icBadgeSilver.png'
				this.images.userImage = '/results/f=ma.png'
				this.images.skillImage1 = '/skills/로봇감수성.png'
				this.images.skillImage2 = '/skills/팩트폭행.png'
				this.images.skillImage3 = '/skills/훈화말씀.png'
				this.images.mateImage = '/results/조마조마.png'
				this.images.countImage = '/results/마그마(임마).png'
				this.images.thumbnailImage = '/thumbnails/썸네일_로봇인가 설마.png'
				break
			case 'istp':
				this.images.rankImage = '/tier/icBadgeSilver.png'
				this.images.userImage = '/results/유니콘.png'
				this.images.skillImage1 = '/skills/딴청피우기.png'
				this.images.skillImage2 = '/skills/로봇감수성.png'
				this.images.skillImage3 = '/skills/무지개반사.png'
				this.images.mateImage = '/results/고구마.png'
				this.images.countImage = '/results/회전목마.png'
				this.images.thumbnailImage = '/thumbnails/썸네일_유니콘.png'
				break
			case 'infp':
				this.images.rankImage = '/tier/icBadgeSilver.png'
				this.images.userImage = '/results/조마조마.png'
				this.images.skillImage1 = '/skills/선즙필승.png'
				this.images.skillImage2 = '/skills/이불킥.png'
				this.images.skillImage3 = '/skills/답정너.png'
				this.images.mateImage = '/results/f=ma.png'
				this.images.countImage = '/results/악마.png'
				this.images.thumbnailImage = '/thumbnails/썸네일_조마조마.png'
				break
			case 'isfp':
				this.images.rankImage = '/tier/icBadgeBronze.png'
				this.images.userImage = '/results/고구마.png'
				this.images.skillImage1 = '/skills/이불킥.png'
				this.images.skillImage2 = '/skills/선즙필승.png'
				this.images.skillImage3 = '/skills/딴청피우기.png'
				this.images.mateImage = '/results/유니콘.png'
				this.images.countImage = '/results/꼬꼬마.png'
				this.images.thumbnailImage = '/thumbnails/썸네일_고구마.png'
				break
			default:
				break
		}
		this.$parent.thumbnailImage = this.images.thumbnailImage
		this.$parent.thumbnailText = '私のコミュ力は' + this.resultString.user.percentage + 'の' + this.resultString.user.rank + 'です！あなたは何ランク？' + ' @sorezore_6'
	},
	methods:{
		openReadMore(){
			this.$analytics.logEvent("open_read_more")
			this.readMore = true
		},
		closeReadMore(){
			this.readMore = false
		},
		moveToRType(rType){
			this.$router.push({ name: 'result', params: {
                type: rType
			}})
		}
	},
	metaInfo() {
		return {
			title: 'コミュ力診断テスト | Connecting',
			meta: [
				{ property: 'og:image', content: 'https://connecting.yplabs.net'+this.images.thumbnailImage},
				{ property: 'og:title', content: '私のコミュ力は' + this.resultString.user.percentage + 'の' + this.resultString.user.rank + 'です！あなたは何ランク？' + ' @sorezore_6'},
				{ property: 'og:description', content: 'Connecting App'},
				{ property: 'twitter:card', content: 'summary_large_image'},
				{ property: 'twitter:image', content: 'https://connecting.yplabs.net'+this.images.thumbnailImage}
			]
		}
	}
}
</script>

<style scoped>
.resultHeader {
	font-size: 24px;
	font-weight: bold;
	color: rgb(34, 34, 34);
	margin-bottom: 4px;
	line-height: 29px;
}

.resultImage {
	width: 139px;
	height: 139px;
	margin-top: 5px;
	margin-bottom: 10px;
}

.rank{
	width: 335px;
	margin-top: 12px;
}

.rankImg{
	width: 24px;
	height: 24px;
}

.rankName{
	line-height: 20px;
	font-size:17px;
	color: rgb(34, 34, 34);
	font-weight: bold;
	display: inline-block;
	margin-left: 4px;
	vertical-align: middle;
}

.rankPercent{
	display: inline-block;
	margin-left: 5px;
	vertical-align: middle;
	font-size: 13px;
	color: rgb(57, 108, 233);
}

.rankStatus {
	display: block;
	width: 335px;
	height: 145px;
	margin: auto;
	border: 1px solid rgba(204, 204, 204, 0.5);
	border-radius: 6px;
}

.status{
	width: 335px;
}

.statusDetail{
	font-size: 13px;
	line-height: 16px;
	color: rgb(51, 51, 51);
	margin-bottom: 7px;
}

.statusBar{
	width: 160px;
	height: 8px;
	padding: unset;
	margin: unset;
	border: unset;
	margin-left: 6px;
}

.statusBar{
	display: inline-block; 
	width: 30px; 
	height: 6px; 
	background-color: rgb(230, 230, 230); 
	margin-left: 2px;
}

.leftBar{
	margin-left: 10px; 
	border-radius: 100px 0px 0px 100px;
}

.rightBar{
	border-radius: 0px 100px 100px 0px;
}

.statusBarFilled{
	background-color: rgb(0, 219, 199) !important;
}

.detail{
	width: 315px;
	margin: auto;
	margin-top: 18px;
	text-align: left;
}

.detailHeader{
	margin-bottom: 10px;
	font-size: 16px;
	font-weight: bold;
	color: rgb(34, 34, 34);
	text-align: left;
	line-height: 19px;
}

.detailText{
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0px;
	color: rgb(51, 51, 51);
	overflow: hidden;
}

.moreButton{
	width: 80px;
	font-size: 15px;
	line-height: 19px;
	border: unset;
	margin: unset;
	margin-top: 3px;
	padding: unset;
	background-color: white;
	color: rgb(37, 120, 192);
	font-weight: bold;
	text-align: left;
}

.skillList{
	width: 315px;
	margin: auto;
	margin-top: 26px;
	margin-bottom: 38px;
}

.skills{
	width: 295px;
	margin-top: 10px;
	margin: auto;
}

.skillImg{
	width: 77px;
	height: 77px;
	margin-bottom: 8px;
}

.skillName{
	font-size: 14px;
	font-weight: bold;
	color: rgb(0, 156, 142);
	line-height: 17px;
	vertical-align: baseline;
}

.mateList{
	width: 335px;
	margin: auto;
	margin-bottom: 34px;
}

.mate{
	width: 161px;
	display: inline-block;
}

.mateHeader{
	font-size: 18px;
	font-weight: bold;
	color: rgb(34, 34, 34);
	margin-bottom: 10px;
	height: 21px;
}

.mateCard{
	width: 161px;
	height: 170px;
	border: 1px solid rgba(204, 204, 204, 0.5);
	border-radius: 6px;
}

.mateImg{
	width: 109px;
	height: 109px;
	margin: auto;
	margin-top: 16px;
}

.mateImg:hover{
	opacity: 0.7;
	cursor: pointer;
}

.mateName{
	line-height: 19px;
	font-size: 16px;
	font-weight: bold;
	color: rgb(0, 156, 142);
	margin-top: 12px;
}

</style>
