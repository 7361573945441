import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        type : null,
    },
    mutations:{
        changeEnv: function (state, data){
            state.type = data.type
        }
    },
    plugins: [createPersistedState()]
});