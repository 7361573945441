<template>
    <div>
        <div class="header">
            <progress-bar :size="6" bg-color="#F8F8F8" bar-color="#00C2B0" :val="currentPercent"></progress-bar>
            <div class="page">
                <span class="currentPageColor">{{currentPage}} </span>
                <span> / 12</span>
            </div>
        </div>
        <transition name="fade">
            <div class="question" v-if="show">
                <div>
                    <b-img class="questionImg" :src="'/selections/'+shuffledQuestion[currentPage-1].questionImg"></b-img>
                </div>
                <span class="questionText">
                    {{shuffledQuestion[currentPage-1].question}}
                </span>
                <div class="answerBox">
                    <b-button :disabled="changingQuestion" variant="light" class="answer" @click="nextQuestion(0)">
                        <div class="answerTextBox">
                            <div class="answerText">
                                {{shuffledQuestion[currentPage-1].answer[0]}}
                            </div>
                        </div>
                    </b-button>
                    <b-button :disabled="changingQuestion" variant="light" class="answer" @click="nextQuestion(1)">
                        <div class="answerTextBox">
                            <div class="answerText">
                                {{shuffledQuestion[currentPage-1].answer[1]}}
                            </div>
                        </div>
                    </b-button>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import ProgressBar from 'vue-simple-progress'
import selectionString from '../assets/string/selection.json'

function shuffle(array) {
    let currentIndex = array.length, temporaryValue, randomIndex
    while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1
        temporaryValue = array[currentIndex]
        array[currentIndex] = array[randomIndex]
        array[randomIndex] = temporaryValue
    }
    return array
}

export default {
    name: 'selection',
    components: {
        ProgressBar
    },
    data() {
        return {
            currentPercent: 100 / 12,
            currentPage: 1,
            show: true,
            changingQuestion: false,
            mbti: {
                e: 0,
                i: 0,
                s: 0,
                n: 0,
                t: 0,
                f: 0,
                j: 0,
                p: 0
            },
            shuffledQuestion: [],
            mbtiResult: ''
        }
    },
    beforeMount() {
        let array = []
        this.shuffledQuestion = []
        for (let i = 0; i < 12; i++) {
            array[i] = i
        }
        shuffle(array)
        for (let i = 0; i < 12; i++) {
            this.shuffledQuestion.push({
                type: selectionString[array[i]].type,
                question: selectionString[array[i]].question,
                answer: selectionString[array[i]].answer,
                questionImg: selectionString[array[i]].questionImg
            })
        }
        this.$analytics.logEvent("view_start_testing")
    },
    methods: {
        nextQuestion(answerNum) {
            this.show = !this.show
            this.changingQuestion = true
            this.countMBTI(answerNum)
            let self = this
            setTimeout(function() {
                self.currentPage++
                    if (self.currentPage >= 13) {
                        self.$router.push({
                            name: 'loading',
                            params: {
                                mbti: self.calculatingMBTI()
                            }
                        })
                    }
                self.currentPercent = 100 / 12 * self.currentPage
                self.show = true
                self.changingQuestion = false
            }, 500)
        },
        countMBTI(answerNum){
            switch(this.shuffledQuestion[this.currentPage-1].type){
                case 'E_I':
                    if(answerNum == 0){
                        this.mbti.e++
                    }
                    else this.mbti.i++
                    break
                case 'S_N':
                    if(answerNum == 0){
                        this.mbti.s++
                    }
                    else this.mbti.n++
                    break
                case 'T_F':
                    if(answerNum == 0){
                        this.mbti.t++
                    }
                    else this.mbti.f++
                    break
                case 'J_P':
                    if(answerNum == 0){
                        this.mbti.j++
                    }
                    else this.mbti.p++
                    break
                default:
                    break
            }
        },
        calculatingMBTI(){
            let mbtiResult = ''
            if(this.mbti.e > this.mbti.i){
                mbtiResult = mbtiResult+'e'
            }
            else{mbtiResult = mbtiResult+'i'}

            if(this.mbti.s > this.mbti.n){
                mbtiResult = mbtiResult+'s' 
            }
            else{mbtiResult = mbtiResult+'n'}

            if(this.mbti.t > this.mbti.f){
                mbtiResult = mbtiResult+'t'
            }
            else{mbtiResult = mbtiResult+'f'}

            if(this.mbti.j > this.mbti.p){
                mbtiResult = mbtiResult+'j'
            }
            else{mbtiResult = mbtiResult+'p'}

            this.$store.commit('changeEnv', {
                    type : mbtiResult
                }) // vuex에 저장(유사시 사용)

            return mbtiResult
        }
    }
}
</script>

<style scoped>
.page {
    text-align: right;
    margin-top: 12px;
    margin-right: 12px;
    font-size: 15px;
}

.currentPageColor {
    color: rgb(0, 156, 142);
}

.questionImg {
    margin-bottom: 10px;
    width: 315px;
    height: 167px;
}

.question {
    margin: 52px 30px 0px 30px;
}

.questionText {
    text-align: left;
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
}

.answerBox {
    margin-top: 28px;
}

.answer {
    display: block;
    border: 1px solid rgb(230, 230, 230);
    background-color: white;
    width: 315px;
    height: 90px;
    border-radius: 10px;
    margin: auto;
    padding: unset;
    margin-bottom: 14px;
}

.answerTextBox {
    width: 279px;
    height: 90px;
    margin: auto;
}

.answerText {
    height: 90px;
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    font-size: 17px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to {
    opacity: 0
}
</style>
