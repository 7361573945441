import Vue from 'vue'
import App from './App.vue'
import router from '../router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueSocialSharing from 'vue-social-sharing'
import VueClipboard from 'vue-clipboard2'
import {store} from "./store"
import firebase from "firebase"
import VueMeta from 'vue-meta'
    
const firebaseConfig = {
  apiKey: "AIzaSyBibne5sjlc6vRNjba1j4GIcrXvT_6wL_0",
  authDomain: "connecting-mbti.firebaseapp.com",
  projectId: "connecting-mbti",
  storageBucket: "connecting-mbti.appspot.com",
  messagingSenderId: "1052968463161",
  appId: "1:1052968463161:web:86db37ab2ee43cf53896e8",
  measurementId: "G-LC5K9MBN18"
}
firebase.initializeApp(firebaseConfig)
firebase.analytics()

VueClipboard.config.autoSetContainer = true
window.Kakao.init("00328324dc749ea39648978ea65bfdd8")
Vue.use(VueClipboard)
Vue.use(VueSocialSharing)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueMeta, {
  refreshOnceOnNavaigation: true
})
Vue.config.productionTip = false
Vue.prototype.$analytics = firebase.analytics()

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
