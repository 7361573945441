<template>
  <div>
        <div class="mainContent">
            <b-img class="logo" src="/imgLogo.png"></b-img>
            <div class="innerContent">
                <div class="title">コミュ力診断テスト</div>
                <div class="subtitle">あなたのコミュ力は上位何パーセント？</div>
            </div>
            <b-img class="mainImage" src="/main.gif"></b-img>
            <button class="startButton" variant="info" @click="startTest()">診断スタート</button>
        </div>
  </div>
</template>

<script>

export default {
  name: 'intro',
  components: {
  },
  methods: {
      startTest(){
          this.$router.push({ name: 'selection' })
      }
  },
  beforeMount(){
      this.$analytics.logEvent("view_intro")
  },
  metaInfo() {
		return {
			title: 'コミュ力診断テスト | Connecting',
			meta: [
				{ property: 'og:image', content: 'https://connecting.yplabs.net/intro.png'},
                { property: 'og:title', content: 'あなたのコミュ力は何位？'},
                { name: 'description', content: 'コミュ力順位から相性がいいタイプまでわかる！'},
                { name: 'keywords', content: '会話力, 心理テスト, コミュ力テスト, 16タイプ, 人間関係, コミュニケーション, 性格タイプ, 会話, コネクティング, connecting'}
			]
		}
	}
}
</script>

<style scoped>
.mainContent{
    min-width: 300px;
    max-width: 600px;
    margin: 0 auto 0 auto;
}

.logo{
    width: 108px;
    height: 27px;
    margin-top: 23px;
}

.innerContent{
    display: block;
    margin-top: 30px;
}

.title{
    display: block;
    font-size: 26px;
    font-weight: bold;
    color: rgb(51, 51, 51);
}

.subtitle{
    display: block;
    margin-top: 10px;
    font-size: 16px;
    color: rgb(102, 102, 102);
}

.mainImage{
    width: 205px;
    height: 205px;
    position: absolute;
    top : 50%;
    left : 50%;
    transform: translate(-50%, -50%);
}

.startButton{
    position: absolute;
    width: 289px;
    height: 53px;
    transform: translate(-50%);
    bottom: 71px;
    border-radius: 27.5px;
    border: none;
    font-weight: bold;
    background-color: rgb(0, 194, 176);
    color: white;
}

.startButton:hover{
    background-color: rgb(0, 174, 156);
}
</style>
