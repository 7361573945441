<template>
  <div>
        <div class="mainContent">
            <b-img class="logo" src="/imgLogo.png"></b-img>
			<div class="rankHeader">コミュ力順位一覧</div>

			<div class="rankCard diamondBox">
				<div class="rankTitle diamondTitle">
					<div class="rankTitleText">ダイヤモンド</div>
				</div>

				<div class="rankIcon" style="margin-top: 37px; margin-left: 57px;">
					<b-img v-if="this.$route.params.type=='enfj'" class="checkBoxImg" src="/icMyRank.png"></b-img>
					<div class="rankBorder diamondRankBorder" v-bind:class="[ ($route.params.type == 'enfj') ? activeClass : errorClass]">
						<b-img @click="moveToType('enfj')" class="rankImg" src="/results/우리엄마.png"></b-img>
					</div>
					<div class="rankName">フワフワ</div>
				</div>
				<div class="rankIcon" style="margin-top: 37px; margin-left: 187px;">
					<b-img v-if="this.$route.params.type=='istj'" class="checkBoxImg" src="/icMyRank.png"></b-img>
					<div class="rankBorder diamondRankBorder" v-bind:class="[ ($route.params.type == 'istj') ? activeClass : errorClass]">
						<b-img @click="moveToType('istj')" class="rankImg" src="/results/얘랑 싸우지마.png"></b-img>
					</div>
					<div class="rankName">ペラペラ</div>
				</div>

			</div>
			<div class="rankCard platinumBox">
				<div class="rankTitle platinumTitle">
					<div class="rankTitleText">プラチナ</div>
				</div>

				<div class="rankIcon" style="margin-top: 37px; margin-left: 16px;">
					<b-img v-if="this.$route.params.type=='estp'" class="checkBoxImg" src="/icMyRank.png"></b-img>
					<div class="rankBorder platinumRankBorder" v-bind:class="[ ($route.params.type == 'estp') ? activeClass : errorClass]">
						<b-img @click="moveToType('estp')" class="rankImg" src="/results/악마.png"></b-img>
					</div>
					<div class="rankName">チクチク</div>
				</div>
				<div class="rankIcon" style="margin-top: 37px; margin-left: 122px;">
					<b-img v-if="this.$route.params.type=='entj'" class="checkBoxImg" src="/icMyRank.png"></b-img>
					<div class="rankBorder platinumRankBorder" v-bind:class="[ ($route.params.type == 'entj') ? activeClass : errorClass]">
						<b-img @click="moveToType('entj')" class="rankImg" src="/results/카리스마.png"></b-img>
					</div>
					<div class="rankName">ピカピカ</div>
				</div>
				<div class="rankIcon" style="margin-top: 37px; margin-left: 229px;">
					<b-img v-if="this.$route.params.type=='infj'" class="checkBoxImg" src="/icMyRank.png"></b-img>
					<div class="rankBorder platinumRankBorder" v-bind:class="[ ($route.params.type == 'infj') ? activeClass : errorClass]">
						<b-img @click="moveToType('infj')" class="rankImg" src="/results/트로이의목마.png"></b-img>
					</div>
					<div class="rankName">モヤモヤ</div>
				</div>

			</div>
			<div class="rankCard goldBox">
				<div class="rankTitle goldTitle">
					<div class="rankTitleText">ゴールド</div>
				</div>

				<div class="rankIcon" style="margin-top: 93px; margin-left: 16px;">
					<b-img v-if="this.$route.params.type=='entp'" class="checkBoxImg" src="/icMyRank.png"></b-img>
					<div class="rankBorder goldRankBorder" v-bind:class="[ ($route.params.type == 'entp') ? activeClass : errorClass]">
						<b-img @click="moveToType('entp')" class="rankImg" src="/results/꼬꼬마.png"></b-img>
					</div>
					<div class="rankName">クスクス</div>
				</div>
				<div class="rankIcon" style="margin-top: 37px; margin-left: 123px;">
					<b-img v-if="this.$route.params.type=='isfj'" class="checkBoxImg" src="/icMyRank.png"></b-img>
					<div class="rankBorder goldRankBorder" v-bind:class="[ ($route.params.type == 'isfj') ? activeClass : errorClass]">
						<b-img @click="moveToType('isfj')" class="rankImg" src="/results/파노라마.png"></b-img>
					</div>
					<div class="rankName">コツコツ</div>
				</div>
				<div class="rankIcon" style="margin-top: 169px; margin-left: 123px;">
					<b-img v-if="this.$route.params.type=='intj'" class="checkBoxImg" src="/icMyRank.png"></b-img>
					<div class="rankBorder goldRankBorder" v-bind:class="[ ($route.params.type == 'intj') ? activeClass : errorClass]">
						<b-img @click="moveToType('intj')" class="rankImg" src="/results/꼼짝마.png"></b-img>
					</div>
					<div class="rankName">ツンツン</div>
				</div>
				<div class="rankIcon" style="margin-top: 93px; margin-left: 230px;">
					<b-img v-if="this.$route.params.type=='esfj'" class="checkBoxImg" src="/icMyRank.png"></b-img>
					<div class="rankBorder goldRankBorder" v-bind:class="[ ($route.params.type == 'esfj') ? activeClass : errorClass]">
						<b-img @click="moveToType('esfj')" class="rankImg" src="/results/천군만마.png"></b-img>
					</div>
					<div class="rankName">ガヤガヤ</div>
				</div>

			</div>
			<div class="rankCard silverBox">
				<div class="rankTitle silverTitle">
					<div class="rankTitleText">シルバー</div>
				</div>

				<div class="rankIcon" style="margin-top: 37px; margin-left: 16px;">
					<b-img v-if="this.$route.params.type=='estj'" class="checkBoxImg" src="/icMyRank.png"></b-img>
					<div class="rankBorder silverRankBorder" v-bind:class="[ ($route.params.type == 'estj') ? activeClass : errorClass]">
						<b-img @click="moveToType('estj')" class="rankImg" src="/results/라떼이즈홀스.png"></b-img>
					</div>
					<div class="rankName">フムフム</div>
				</div>
				<div class="rankIcon" style="margin-top: 37px; margin-left: 123px;">
					<b-img v-if="this.$route.params.type=='enfp'" class="checkBoxImg" src="/icMyRank.png"></b-img>
					<div class="rankBorder silverRankBorder" v-bind:class="[ ($route.params.type == 'enfp') ? activeClass : errorClass]">
						<b-img @click="moveToType('enfp')" class="rankImg" src="/results/회전목마.png"></b-img>
					</div>
					<div class="rankName">キョロキョロ</div>
				</div>
				<div class="rankIcon" style="margin-top: 37px; margin-left: 230px;">
					<b-img v-if="this.$route.params.type=='intp'" class="checkBoxImg" src="/icMyRank.png"></b-img>
					<div class="rankBorder silverRankBorder" v-bind:class="[ ($route.params.type == 'intp') ? activeClass : errorClass]">
						<b-img @click="moveToType('intp')" class="rankImg" src="/results/f=ma.png"></b-img>
					</div>
					<div class="rankName">カチカチ</div>
				</div>
				<div class="rankIcon" style="margin-top: 169px; margin-left: 16px;">
					<b-img v-if="this.$route.params.type=='istp'" class="checkBoxImg" src="/icMyRank.png"></b-img>
					<div class="rankBorder silverRankBorder" v-bind:class="[ ($route.params.type == 'istp') ? activeClass : errorClass]">
						<b-img @click="moveToType('istp')" class="rankImg" src="/results/유니콘.png"></b-img>
					</div>
					<div class="rankName">ルンルン</div>
				</div>
				<div class="rankIcon" style="margin-top: 169px; margin-left: 123px;">
					<b-img v-if="this.$route.params.type=='esfp'" class="checkBoxImg" src="/icMyRank.png"></b-img>
					<div class="rankBorder silverRankBorder" v-bind:class="[ ($route.params.type == 'esfp') ? activeClass : errorClass]">
						<b-img @click="moveToType('esfp')" class="rankImg" src="/results/마그마(임마).png"></b-img>
					</div>
					<div class="rankName">メラメラ</div>
				</div>
				<div class="rankIcon" style="margin-top: 169px; margin-left: 230px;">
					<b-img v-if="this.$route.params.type=='infp'" class="checkBoxImg" src="/icMyRank.png"></b-img>
					<div class="rankBorder silverRankBorder" v-bind:class="[ ($route.params.type == 'infp') ? activeClass : errorClass]">
						<b-img @click="moveToType('infp')" class="rankImg" src="/results/조마조마.png"></b-img>
					</div>
					<div class="rankName">モジモジ</div>
				</div>

			</div>
			<div class="rankCard bronzeBox">
				<div class="rankTitle bronzeTitle">
					<div class="rankTitleText">ブロンズ</div>
				</div>

				<div class="rankIcon" style="margin-top: 37px; margin-left: 123px;">
					<b-img v-if="this.$route.params.type=='isfp'" class="checkBoxImg" src="/icMyRank.png"></b-img>
					<div class="rankBorder bronzeRankBorder" v-bind:class="[ ($route.params.type == 'isfp') ? activeClass : errorClass]">
						<b-img @click="moveToType('isfp')" class="rankImg" src="/results/고구마.png"></b-img>
					</div>
					<div class="rankName">ウルウル</div>
				</div>
			</div>

			<a href="#" @click="returnToResult()">
				<div class="returnButton">
					<div class="buttonText">
						戻る
					</div>
				</div>
			</a>
        </div>
  </div>
</template>

<script>
export default {
	name: 'rank',
	components: {
	},
	data(){
		return{
			activeClass: 'rankBorderShadow',
			errorClass: '',
		}
	},
	beforeCreate() {
		window.scrollTo(0, 0);
	},
	beforeMount(){
		this.$analytics.logEvent("view_rank")
	},
	methods:{
		returnToResult(){
			this.$router.push({ name: 'result', params: {
                type: this.$route.params.type
            } })
		},
		moveToType(type){
			this.$router.push({ name: 'result', params: {
                type: type
            } })
		}
	}
}
</script>

<style scoped>
.mainContent{
    min-width: 300px;
    max-width: 600px;
    margin: 0 auto 0 auto;
}

.logo{
    width: 108px;
    height: 27px;
    margin-top: 23px;
}

.rankHeader{
	font-size: 22px;
	line-height: 27px;
	font-weight: bold;
	color: rgb(34, 34, 34);
	margin-top: 47px;
	margin-bottom: 41px;
}

.rankCard{
	position: relative;
	width: 335px;
	border-radius: 8px;
	margin: auto;
	margin-bottom: 37px;
}

.diamondBox{
	height: 172px;
	background-color: rgb(238, 251, 250);
	border: 1px solid rgb(214, 248, 242);
}

.platinumBox{
	height: 172px;
	background-color: rgb(241, 239, 255);
	border: 1px solid rgba(108, 77, 255, 0.1);
}

.goldBox{
	height: 300px;
	background-color: rgb(255, 249, 230);
	border: 1px solid rgb(255, 235, 211);
}

.silverBox{
	height: 300px;
	background-color: rgb(248, 248, 248);
	border: 1px solid rgb(230, 230, 230);
}

.bronzeBox{
	height: 172px;
	background-color: rgb(249, 244, 240);
	border: 1px solid rgb(237, 230, 226);
}

.rankTitle{
	position: absolute;
	width: 148px;
	height: 34px;
	border-radius: 18px;
	font-size: 16px;
	font-weight: bold;
	line-height: 19px;
	background-color: white;
	left: 50%;
	transform: translate(-50%);
	margin-top: -17px;
}

.rankTitleText{
	width: 148px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.diamondTitle{
	border: 1px solid rgba(0, 219, 199, 0.5);
}

.platinumTitle{
	border: 1px solid rgba(108, 77, 255, 0.5);
}

.goldTitle{
	border: 1px solid rgba(255, 177, 133, 0.5);
}

.silverTitle{
	border: 1px solid rgba(170, 170, 170, 0.5);
}

.bronzeTitle{
	border: 1px solid rgba(195, 168, 149, 0.5);
}

.rankIcon{
	position: absolute;
	width: 90px;
}

.rankBorder{
	width: 90px;
	height: 90px;
	border-radius: 90px;
	background-color: white;
}

.rankBorderShadow{
	box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2);
}

.rankImg{
	width: 62px;
	height: 62px;
	margin: auto;
	margin-top: 14px;
}

.rankImg:hover{
	opacity: 0.7;
	cursor: pointer;
}

.rankName{
	font-size: 14px;
	line-height: 17px;
	font-weight: bold;
	margin-top: 7px;
}

.checkBoxImg{
	position: absolute;
	width: 20px;
	height: 20px;
	right: 0px;
}

.diamondRankBorder{
	border: 1px solid rgb(0, 219, 199);
}

.platinumRankBorder{
	border: 1px solid rgb(108, 77, 255);
}

.goldRankBorder{
	border: 1px solid rgb(255, 177, 133);
}

.silverRankBorder{
	border: 1px solid rgb(170, 170, 170);
}

.bronzeRankBorder{
	border: 1px solid rgb(195, 168, 149);
}

.returnButton{
	position: relative;
	margin-top: 7px;
	width: 335px;
	height: 60px;
	background-color: rgb(0, 194, 176);
	border-radius: 8px;
	margin: auto;
	margin-bottom: 44px;
	font-size: 18px;
	font-weight: bold;
	line-height: 21px;
	color: white;
}

.returnButton:hover{
	background-color: rgb(0, 174, 156);
}

.buttonText{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
</style>
