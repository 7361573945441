<template>
    <div>
        <div class="mainContent">
            <b-img class="logo" src="/imgLogo.png"></b-img>
            <b-img class="calculating" src="/calculating.gif"></b-img>
        </div>
    </div>
</template>

<script>
export default {
    name: 'loading',
    components: {},
    mounted(){
        let self = this
        let mbti = this.$route.params.mbti
        setTimeout(function() {
                self.$router.push({ name: 'result', params:{
                    type: mbti
                }})
            }, 3000);
    }
}
</script>

<style scoped>
.mainContent{
    min-width: 300px;
    max-width: 600px;
    margin: 0 auto 0 auto;
}

.logo{
    width: 108px;
    height: 27px;
    margin-top: 23px;
}

.calculating{
    width: 296px;
    height: 296px;
    position: absolute;
    top : 50%;
    left : 50%;
    margin-left: -148px;
    margin-top: -148px;
}
</style>
