<template>
  <div id="app">
    <router-view :key="$route.fullPath" @showLoading="showLoading"></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  beforeCreate(){
    document.title = 'コミュ力診断テスト  | Connecting'
  }
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
