import Vue from 'vue';
import VueRouter from 'vue-router';
import Intro from '../src/components/Intro.vue'
import Selection from '../src/components/Selection.vue'
import Loading from '../src/components/Loading.vue'
import Result from '../src/components/Result.vue'
import Rank from '../src/components/Rank.vue'
import RedirectIntro from '../src/components/RedirectIntro.vue'
import resultJson from '../src/assets/string/result.json'

Vue.use(VueRouter);

export default new VueRouter({
  mode: 'history',
  routes: [
    {
        path: '/intro',
        name: 'intro',
        component: Intro,
        props: true
    },
    {
        path: '/selection',
        name: 'selection',
        component: Selection,
        props: true
    },
    {
        path: '/loading',
        name: 'loading',
        component: Loading,
        props: true
    },
    {
        path: '/result/:type',
        name: 'result',
        component: Result,
        props: true,
        beforeEnter(to, from, next){
            let checkRouter = false
            for(const result in resultJson['JP']){
                if(result == to.params.type){
                    checkRouter = true
                }
            }

            if(checkRouter){ // 정상접근
                next()
            }
            else{ // 비정상 접근시 intro 로
                next({name: 'redirectintro'})
            }
        }
    },
    {
        path: '/rank/:type',
        name: 'rank',
        component: Rank,
        props: true,
        beforeEnter(to, from, next){
            let checkRouter = false
            for(const result in resultJson['JP']){
                if(result == to.params.type){
                    checkRouter = true
                }
            }

            if(checkRouter){ // 정상접근
                next()
            }
            else{ // 비정상 접근시 intro 로
                next({name: 'redirectintro'})
            }
        }
    },
    {
        path: '*',
        name: 'redirectintro',
        component: RedirectIntro,
        props: true
    },
  ]
})