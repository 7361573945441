<template>
    <div>
        <div class="detailHeader">
            SNSで友達にシェアする
        </div>
        <div class="share">
            <ShareNetwork
                network="twitter"
                :url="copyUrl"
                :title="$parent.thumbnailText"
                :popup="{width: userWidth, height: userHeight}"
                style="margin-right: 28px"
            >
                <b-img class="shareImg" @click="twitterShare()" src="/share/btnTwitter.png" style="margin-right: 28px"></b-img>
            </ShareNetwork>
            <ShareNetwork
                network="line"
                :url="copyUrl"
                :title="$parent.thumbnailText"
                :popup="{width: userWidth, height: userHeight}"
                style="margin-right: 28px"
            >
                <b-img class="shareImg" @click="lineShare()" src="/share/btnLine.png" style="margin-right: 28px"></b-img>
            </ShareNetwork>
            <ShareNetwork
                network="facebook"
                :url="copyUrl"
                :title="$parent.thumbnailText"
                :popup="{width: userWidth, height: userHeight}"
                style="margin-right: 28px"
            >
                <b-img class="shareImg" @click="facebookShare()" src="/share/btnFacebook.png" style="margin-right: 28px"></b-img>
            </ShareNetwork>
            <!-- facebook -->
            <!-- <a href="#" style="margin-right: 28px"><b-img class="shareImg" @click="kakaoShare()" src="/share/btnKakao.png"></b-img></a>
            kakao -->
            <button class="shareButton" type="button" v-clipboard:copy="copyUrl" @click="onCopy()"><b-img class="shareImg" src="/share/btnShare.png"></b-img></button>
            <!-- copy -->
        </div> <!-- share -->

        <div class="findMore">
            <div class="findHeader">コネクティングの通話でコミュ力UPしちゃうかも？</div>
            <button class="findButton" @click="moveToAppStore()">試してみる!</button>
            <b-img class="blurImg" src="/share/imgBlurredRank.png"></b-img>
        </div>
        <b-img class="kikiImg" @click="moveToInsta()" src="/kiki.png"></b-img>
        <div>
            <button class="restart" @click="restartTest()">最初に戻る</button>
        </div>
        <div>
            <button class="rankDetail" @click="moveToOverallRanking()">ランキング表を見る</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'resultdetail',
    components: {},
    data(){
        return{
            userHeight: window.outerHeight,
            userWidth: window.outerWidth,
            copyUrl: 'https://connecting.yplabs.net/result/' + this.$route.params.type
        }
    },
    methods: {
        // kakaoShare() {
        //     this.$analytics.logEvent("share_kakao")
        //     console.log(this.$parent.thumbnailText)

        //     window.Kakao.Link.sendDefault({
        //         objectType: 'feed',
        //         content: {
        //             title:  this.$parent.thumbnailText ,
        //             imageUrl: 'https://mbti.yplabs.net' + this.$parent.thumbnailImage,
        //             link: {
        //                 mobileWebUrl: 'https://hellocnt.onelink.me/yC9C/myfriend'
        //             },
        //             imageHeight: 400,
        //             imageWidth: 800
        //         },
        //         buttons: [
        //             {
        //                 title: '말싸움 능력치 측정하러 가기',
        //                 link: {
        //                     mobileWebUrl: 'https://mbti.yplabs.net/result/' + this.$route.params.type,
        //                 },
        //             }
        //         ]
        //     });
        // },
        facebookShare(){
            this.$analytics.logEvent("share_facebook")
        },
        lineShare(){
            this.$analytics.logEvent("share_line")
        },
        twitterShare(){
            this.$analytics.logEvent("share_twitter")
        },
        onCopy() {
            this.$analytics.logEvent("share_copy")

            this.$bvToast.toast('URLをコピーしました',{
                variant: 'info',
                toaster: 'b-toaster-top-center',
                solid: true,
                noCloseButton: true,
                autoHideDelay: 1000,
                bodyClass: ['alertToast']
            })
        },
        restartTest(){
            this.$router.push({name: 'intro'})
        },
        moveToOverallRanking(){
            this.$router.push({ name: 'rank', params: {
                type: this.$route.params.type
            } })
        },
        moveToAppStore(){
            this.$analytics.logEvent("move_to_appstore")

            window.open("https://hellocnt.onelink.me/yC9C/fight")
        },
        moveToInsta(){
            window.open("https://twitter.com/sorezore_6")
        }
    }
}
</script>

<style scoped>
a{
    display: inline-block;
    width: 50px;
    height: 50px;
}

.detailHeader{
    font-size: 17px;
    line-height: 20px;
    font-weight: bold;
    color: rgb(34, 34, 34);
    margin-bottom: 18px;
}

.share{
    margin-bottom: 54px;
}

.shareImg{
    width: 50px;
    height: 50px;
}

.shareImg:hover{
    opacity: 0.7;
}

.shareButton{
    padding: unset;
    border: unset;
    margin: unset;
    background-color: white;
}

.findMore{
    margin-bottom: 11px;
    position: relative;
}

.findHeader{
    font-size: 17px;
    line-height: 20px;
    font-weight: bold;
    color: rgb(34, 34, 34);
}

.blurImg{
    width:100%;
    max-width:320px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.kikiImg{
    width:100%;
    max-width: 270px;
    margin-bottom: 20px;
}

.kikiImg:hover{
    opacity: 0.7;
	cursor: pointer;
}

.findButton{
    z-index: 3;
    position: absolute;
    width: 166px;
    height: 47px;
    border-radius: 23.5px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: unset;
    padding: unset;
    font-size: 17px;
    line-height: 20px;
    font-weight: bold;
    left: 50%;
    top: 51%;
    transform: translate(-50%, -50%);
}

.findButton:hover{
    background-color: rgb(0, 0, 0, 0.6);
}

.rankDetail{
    width: 315px;
    height: 52px;
    border-radius: 8px;
    padding: unset;
    border: 1px solid rgb(204, 204, 204);
    margin: unset;
    margin-bottom: 48px;
    background-color: white;
    font-weight: bold;
    font-size: 17px;
}

.restart{
    width: 315px;
    height: 52px;
    border-radius: 8px;
    border: unset;
    padding: unset;
    margin: unset;
    margin-bottom: 11px;
    background-color: rgb(0, 194, 176);
    font-weight: bold;
    font-size: 17px;
    color: white;
}

.restart:hover{
    background-color: rgb(0, 174, 156);
}

.rankDetail:hover{
    background-color: rgb(230, 230, 230);
}

.b-toast{
    text-align: center !important;
    width: 300px;
    margin: auto;
}

</style>
